<template>
  <div>
    <div class="db_top">
      <img src="../../assets/dialog.png" alt="" />
    </div>
    <div class="db_middle">
      <div class="db_serve" v-if="serviceMsg !== null">
        <div class="db_serMsg">
          <span>{{ serviceMsg }}</span>
        </div>
      </div>
      <div class="db_user" v-if="userMsg !== null">
        <div class="db_userMsg">
          <span>{{ userMsg }}</span>
        </div>
      </div>
    </div>
    <div class="db_footer">
      <div class="db_shortcut">
        <a-button v-for="(btn, index) in btnList" :key="index">{{
          btn
        }}</a-button>
      </div>
      <div class="db_input">
        <a-input placeholder="还有什么可以帮助您吗？"></a-input>
        <a-icon type="plus" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "绑定金恪康养会员卡",
      btnList: ["金点", "好礼过期", "云闪付未减免", "运费未减", "问题未解决"],
      serviceMsg: null,
      userMsg: null,
    };
  },
  created() {},
  mounted() {
    this.getServiceTel();
  },
  methods: {
    async getServiceTel() {
      await this.$service
        .get("/mc/api/service/tel")
        .then((res) => {
          if (res.data.resultCode === 0) {
            this.serviceMsg = res.data.data;
          } else {
            this.$message.warning(res.data.resultMsg);
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.db_top {
  height: 9vh;
  box-shadow: 0 0.02rem 0.08rem 0 rgba(0, 0, 0, 0.18);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  img {
    width: 0.32rem;
    margin-bottom: 0.04rem;
  }
}
.db_middle {
  height: 77vh;
  margin-top: 0.05rem;
  background: #f2f3f5;
  .db_serve {
    padding: 0.13rem 0.07rem;
    .db_serMsg {
      display: inline-block;
      background: #fefffe;
      border-radius: 0.03rem 0.16rem 0.16rem 0.16rem;
      font-size: 0.13rem;
      font-weight: 400;
      color: #111111;
      line-height: 0.18rem;
      padding: 0.07rem 0.14rem;
    }
  }
  .db_user {
    padding: 0.13rem 0.07rem;
    text-align: right;
    .db_userMsg {
      display: inline-block;
      background: #fefffe;
      border-radius: 0.16rem 0.03rem 0.16rem 0.16rem;
      font-size: 0.13rem;
      font-weight: 400;
      color: #111111;
      line-height: 0.18rem;
      padding: 0.07rem 0.14rem;
    }
  }
}
.db_footer {
  background: #eef0f1;
  padding: 0.04rem 0.07rem;
  height: 13vh;
  .db_shortcut {
    overflow-x: scroll;
    white-space: nowrap;
    ::v-deep .ant-btn {
      margin-right: 0.06rem;
      height: 0.28rem;
      background: #fefffe;
      border-radius: 19px;
      font-size: 13px;
      font-weight: 400;
      color: #00a862;
      line-height: 18px;
      padding: 0 10px;
    }
  }
  .db_input {
    display: flex;
    align-items: center;
    padding: 0.12rem 0;
    ::v-deep .ant-input {
      border-radius: 0.19rem;
      margin-right: 0.08rem;
    }
    .anticon {
      padding: 0 0.07rem;
    }
  }
}
</style>
